import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { 
  ArrowForward, 
  Calculate, 
  Timeline, 
  TrendingUp, 
  MonetizationOn,
  ShowChart,
  History,
  Update
} from '@mui/icons-material';

export default function InflationLanding() {
  const features = [
    {
      icon: <Calculate className="w-6 h-6" />,
      title: 'Historical Inflation Data',
      description: 'Calculate inflation using real US Consumer Price Index (CPI) data from trusted government sources.'
    },
    {
      icon: <Timeline className="w-6 h-6" />,
      title: 'Multiple Time Periods',
      description: 'Compare purchasing power across different years from 2010 to present day.'
    },
    {
      icon: <TrendingUp className="w-6 h-6" />,
      title: 'Future Value Projections',
      description: 'Estimate future costs and plan your finances with confidence using historical trends.'
    },
    {
      icon: <MonetizationOn className="w-6 h-6" />,
      title: 'Accurate Calculations',
      description: 'Get precise year-by-year breakdowns of how inflation affects your money.'
    },
    {
      icon: <ShowChart className="w-6 h-6" />,
      title: 'Visual Analytics',
      description: 'View interactive charts showing the change in purchasing power over time.'
    },
    {
      icon: <Update className="w-6 h-6" />,
      title: 'Regular Updates',
      description: 'Stay current with the latest inflation data and economic trends.'
    }
  ];

  const useCases = [
    {
      title: 'Personal Finance',
      description: 'Understand how inflation affects your savings and plan for future expenses.',
      examples: ['Retirement planning', 'Long-term savings', 'Investment decisions']
    },
    {
      title: 'Business Planning',
      description: 'Make informed decisions about pricing, costs, and long-term contracts.',
      examples: ['Price adjustments', 'Contract values', 'Cost projections']
    },
    {
      title: 'Historical Analysis',
      description: 'Compare monetary values across different time periods accurately.',
      examples: ['Historical comparisons', 'Asset valuations', 'Economic research']
    }
  ];

  return (
    <div className="min-h-screen bg-slate-50">
      <Helmet>
        <title>Inflation Calculator | Track Money Value Over Time | Lipana</title>
        <meta name="description" content="Calculate how inflation affects your money's value over time. Use historical CPI data to compare purchasing power across years and make informed financial decisions." />
        <meta name="keywords" content="inflation calculator, purchasing power calculator, CPI calculator, money value calculator, inflation rate calculator, historical inflation" />
        <meta property="og:title" content="Inflation Calculator | Track Money Value Over Time" />
        <meta property="og:description" content="Calculate how inflation affects your money's value over time using historical CPI data." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lipana.app/calculators/inflation" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Inflation Calculator | Track Money Value Over Time" />
        <meta name="twitter:description" content="Calculate how inflation affects your money's value over time using historical CPI data." />
        <link rel="canonical" href="https://lipana.app/calculators/inflation" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Inflation Calculator",
            "description": "Calculate how inflation affects your money's value over time using historical CPI data.",
            "url": "https://lipana.app/calculators/inflation",
            "applicationCategory": "FinanceApplication",
            "operatingSystem": "Any",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            }
          })}
        </script>
      </Helmet>

      {/* Hero Section with Gradient Background */}
      <div className="relative bg-gradient-to-br from-blue-600 to-blue-800 text-white overflow-hidden">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[length:16px_16px]"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-20">
          <div className="text-center">
            <h1 className="text-4xl font-bold sm:text-5xl lg:text-6xl mb-6">
              Inflation Calculator
            </h1>
            <p className="text-xl text-blue-100 max-w-2xl mx-auto mb-8">
              Track how inflation affects your money's value over time and make informed financial decisions.
            </p>
            <div className="flex justify-center gap-4">
              <Link
                to="/calculators/inflation/calculate"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-white text-blue-600 hover:bg-blue-50 transition-colors shadow-lg hover:shadow-xl gap-2 font-medium"
              >
                Calculate Inflation
                <ArrowForward className="w-5 h-5" />
              </Link>
              <a
                href="#how-it-works"
                className="inline-flex items-center px-6 py-3 rounded-lg border-2 border-white/20 hover:bg-white/10 transition-colors gap-2 font-medium"
              >
                Learn More
                <History className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-16 bg-gradient-to-t from-slate-50 to-transparent"></div>
      </div>

      {/* Features Grid */}
      <div className="py-20 bg-slate-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-slate-900 text-center mb-12">
            Powerful Features for Accurate Calculations
          </h2>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => (
              <div
                key={index}
                className="relative p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 hover:translate-y-[-2px]"
              >
                <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-blue-100 text-blue-600 mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-lg font-semibold text-slate-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-slate-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div id="how-it-works" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-slate-900 text-center mb-16">
            How It Works
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            <div className="text-center relative">
              <div className="w-16 h-16 flex items-center justify-center rounded-full bg-blue-100 text-blue-600 mx-auto mb-6 text-xl font-bold">
                1
              </div>
              <h3 className="text-xl font-semibold text-slate-900 mb-3">
                Enter Amount
              </h3>
              <p className="text-slate-600">
                Input any dollar amount you want to adjust for inflation.
              </p>
              {/* Connector line for desktop */}
              <div className="hidden md:block absolute top-8 left-[60%] w-full h-[2px] bg-blue-100"></div>
            </div>
            <div className="text-center relative">
              <div className="w-16 h-16 flex items-center justify-center rounded-full bg-blue-100 text-blue-600 mx-auto mb-6 text-xl font-bold">
                2
              </div>
              <h3 className="text-xl font-semibold text-slate-900 mb-3">
                Choose Time Period
              </h3>
              <p className="text-slate-600">
                Select your start and end years to compare values across time.
              </p>
              {/* Connector line for desktop */}
              <div className="hidden md:block absolute top-8 left-[60%] w-full h-[2px] bg-blue-100"></div>
            </div>
            <div className="text-center">
              <div className="w-16 h-16 flex items-center justify-center rounded-full bg-blue-100 text-blue-600 mx-auto mb-6 text-xl font-bold">
                3
              </div>
              <h3 className="text-xl font-semibold text-slate-900 mb-3">
                Get Results
              </h3>
              <p className="text-slate-600">
                View detailed results with interactive charts and analysis.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Use Cases Section */}
      <div className="py-20 bg-slate-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-slate-900 text-center mb-12">
            Perfect For
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            {useCases.map((useCase, index) => (
              <div key={index} className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-xl font-semibold text-slate-900 mb-3">
                  {useCase.title}
                </h3>
                <p className="text-slate-600 mb-4">
                  {useCase.description}
                </p>
                <ul className="space-y-2">
                  {useCase.examples.map((example, i) => (
                    <li key={i} className="flex items-center text-slate-700">
                      <ArrowForward className="w-4 h-4 text-blue-600 mr-2" />
                      {example}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-20 bg-gradient-to-br from-blue-600 to-blue-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Calculate Inflation?
          </h2>
          <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
            Use our calculator to understand how inflation impacts your money's value over time.
          </p>
          <Link
            to="/calculators/inflation/calculate"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-blue-600 hover:bg-blue-50 transition-colors shadow-lg hover:shadow-xl gap-2 text-lg font-medium"
          >
            Start Calculating
            <ArrowForward className="w-6 h-6" />
          </Link>
        </div>
      </div>
    </div>
  );
} 