import React, { useState } from 'react';
import { TextField, Button, Card, Typography, Box, Select, MenuItem, FormControl, InputLabel, Tooltip, IconButton } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { ArrowForward, Info, Share, Print, Download } from '@mui/icons-material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

// Historical inflation rates by year (US CPI data)
const inflationRates: Record<number, number> = {
  2023: 3.4,
  2022: 8.0,
  2021: 4.7,
  2020: 1.4,
  2019: 2.3,
  2018: 2.4,
  2017: 2.1,
  2016: 1.3,
  2015: 0.1,
  2014: 1.6,
  2013: 1.5,
  2012: 2.1,
  2011: 3.2,
  2010: 1.6
};

const currentYear = new Date().getFullYear();
const years = Object.keys(inflationRates).map(Number).sort((a, b) => b - a);

export default function InflationCalculator() {
  const [startValue, setStartValue] = useState('1000');
  const [startYear, setStartYear] = useState(2020);
  const [endYear, setEndYear] = useState(2023);
  const [result, setResult] = useState<number | null>(null);
  const [chartData, setChartData] = useState<any>(null);
  const [yearlyBreakdown, setYearlyBreakdown] = useState<Array<{ year: number; value: number; rate: number }>>([]);

  const calculateInflation = () => {
    if (!startValue || !startYear || !endYear) return;

    const initialAmount = parseFloat(startValue);
    if (isNaN(initialAmount) || initialAmount <= 0) return;

    // If calculating backwards (end year is before start year)
    const isBackwards = endYear < startYear;
    const yearStart = isBackwards ? endYear : startYear;
    const yearEnd = isBackwards ? startYear : endYear;

    // Create array of years in sequence
    const yearRange = Array.from(
      { length: yearEnd - yearStart + 1 },
      (_, i) => yearStart + i
    );

    let currentAmount = initialAmount;
    const values = [currentAmount];
    const breakdown = [{ year: yearStart, value: currentAmount, rate: 0 }];

    // Calculate year by year
    for (let i = 1; i < yearRange.length; i++) {
      const year = yearRange[i];
      const inflationRate = inflationRates[year] || 3.0;
      
      if (isBackwards) {
        currentAmount /= (1 + inflationRate / 100);
      } else {
        currentAmount *= (1 + inflationRate / 100);
      }
      values.push(currentAmount);
      breakdown.push({ year, value: currentAmount, rate: inflationRate });
    }

    // If calculating backwards, reverse the results
    const finalAmount = isBackwards ? values[values.length - 1] : currentAmount;
    setResult(finalAmount);
    setYearlyBreakdown(isBackwards ? breakdown.reverse() : breakdown);

    // Update chart
    updateChart(yearRange, isBackwards ? values.reverse() : values);
  };

  const updateChart = (years: number[], values: number[]) => {
    setChartData({
      labels: years,
      datasets: [
        {
          label: 'Value Over Time',
          data: values,
          borderColor: 'rgb(59, 130, 246)',
          backgroundColor: 'rgba(59, 130, 246, 0.1)',
          tension: 0.4,
          fill: true
        },
      ],
    });
  };

  const handleShare = () => {
    if (result === null) return;
    const text = `Check out how inflation affects money value over time! $${parseFloat(startValue).toFixed(2)} in ${startYear} is worth $${result.toFixed(2)} in ${endYear}. Calculate yours at https://lipana.app/calculators/inflation`;
    if (navigator.share) {
      navigator.share({
        title: 'Inflation Calculator Results',
        text: text,
        url: 'https://lipana.app/calculators/inflation'
      });
    } else {
      navigator.clipboard.writeText(text);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="min-h-screen bg-slate-50 pt-24 pb-12 px-4">
      <Helmet>
        <title>Calculate Inflation Impact | Money Value Calculator | Lipana</title>
        <meta name="description" content="Calculate how inflation affects your money's purchasing power over time using historical US CPI data. Make informed financial decisions with our accurate inflation calculator." />
        <meta name="keywords" content="inflation calculator, purchasing power calculator, CPI calculator, money value calculator, inflation rate calculator" />
        <meta property="og:title" content="Calculate Inflation Impact | Money Value Calculator" />
        <meta property="og:description" content="Calculate how inflation affects your money's purchasing power using historical US CPI data." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lipana.app/calculators/inflation/calculate" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Calculate Inflation Impact | Money Value Calculator" />
        <meta name="twitter:description" content="Calculate how inflation affects your money's purchasing power using historical US CPI data." />
        <link rel="canonical" href="https://lipana.app/calculators/inflation/calculate" />
      </Helmet>

      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <Typography variant="h4" component="h1" className="text-slate-900">
            Calculate Inflation Impact
          </Typography>
          <div className="flex gap-2">
            <Tooltip title="Share Results">
              <IconButton onClick={handleShare} className="text-slate-600 hover:text-blue-600">
                <Share />
              </IconButton>
            </Tooltip>
            <Tooltip title="Print Results">
              <IconButton onClick={handlePrint} className="text-slate-600 hover:text-blue-600">
                <Print />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <Card className="p-6 shadow-lg mb-8 print:shadow-none">
          <div className="space-y-6">
            <div className="grid gap-6 sm:grid-cols-2">
              <TextField
                label="Initial Amount"
                type="number"
                value={startValue}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || parseFloat(value) >= 0) {
                    setStartValue(value);
                    setResult(null);
                    setChartData(null);
                    setYearlyBreakdown([]);
                  }
                }}
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <span className="text-slate-500 mr-2">$</span>,
                }}
              />
              
              <FormControl fullWidth>
                <InputLabel>Start Year</InputLabel>
                <Select
                  value={startYear}
                  label="Start Year"
                  onChange={(e) => {
                    setStartYear(Number(e.target.value));
                    setResult(null);
                    setChartData(null);
                    setYearlyBreakdown([]);
                  }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>End Year</InputLabel>
                <Select
                  value={endYear}
                  label="End Year"
                  onChange={(e) => {
                    setEndYear(Number(e.target.value));
                    setResult(null);
                    setChartData(null);
                    setYearlyBreakdown([]);
                  }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <Button
              variant="contained"
              onClick={calculateInflation}
              fullWidth
              size="large"
              className="bg-blue-600 hover:bg-blue-700 print:hidden"
              endIcon={<ArrowForward />}
            >
              Calculate
            </Button>

            {result !== null && (
              <Box className="space-y-6">
                <div className="p-6 bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg border border-blue-200">
                  <Typography variant="h6" className="text-slate-900 mb-4">
                    Summary
                  </Typography>
                  <div className="space-y-3">
                    <Typography className="text-slate-700">
                      Original Value ({startYear}):
                      <span className="font-semibold ml-2">
                        {parseFloat(startValue).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </span>
                    </Typography>
                    <Typography className="text-slate-700">
                      Adjusted Value ({endYear}):
                      <span className="font-semibold ml-2">
                        {result.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </span>
                    </Typography>
                    <Typography className="text-slate-700">
                      Total Change:
                      <span className={`font-semibold ml-2 ${result > parseFloat(startValue) ? 'text-red-600' : result < parseFloat(startValue) ? 'text-green-600' : ''}`}>
                        {((result / parseFloat(startValue) - 1) * 100).toFixed(1)}%
                      </span>
                    </Typography>
                  </div>
                </div>

                {chartData && (
                  <div className="p-6 bg-white rounded-lg border border-slate-200">
                    <Typography variant="h6" className="text-slate-900 mb-4">
                      Value Over Time
                    </Typography>
                    <div className="h-[300px]">
                      <Line
                        data={chartData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: false
                            },
                            tooltip: {
                              callbacks: {
                                label: (context) => {
                                  return `$${context.parsed.y.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}`;
                                }
                              }
                            }
                          },
                          scales: {
                            y: {
                              beginAtZero: false,
                              ticks: {
                                callback: (value) => `$${value.toLocaleString()}`
                              }
                            }
                          },
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="p-6 bg-white rounded-lg border border-slate-200">
                  <Typography variant="h6" className="text-slate-900 mb-4">
                    Year-by-Year Breakdown
                  </Typography>
                  <div className="space-y-3">
                    {yearlyBreakdown.map((item, index) => (
                      <div key={index} className="flex items-center justify-between p-3 rounded-lg bg-slate-50">
                        <div>
                          <Typography className="text-slate-900 font-medium">
                            {item.year}
                          </Typography>
                          <Typography className="text-slate-600 text-sm">
                            Inflation Rate: {item.rate}%
                          </Typography>
                        </div>
                        <Typography className="text-slate-900 font-medium">
                          {item.value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Box>
            )}
          </div>
        </Card>

        <div className="bg-white rounded-lg p-6 shadow-sm border border-slate-200 print:hidden">
          <div className="flex items-start gap-3">
            <Info className="text-blue-600 w-6 h-6 flex-shrink-0 mt-1" />
            <div>
              <Typography variant="h6" className="text-slate-900 mb-2">
                About This Calculator
              </Typography>
              <Typography className="text-slate-600">
                This calculator uses historical US Consumer Price Index (CPI) data to show how the purchasing power of money changes over time. 
                The calculations are based on year-over-year inflation rates from official government sources. For years where historical data 
                is not available, we use an average inflation rate of 3%. You can calculate both forward (inflation) and backward (deflation) 
                between any two years from 2010 to present.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 